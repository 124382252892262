<template>
  <v-container class="pa-5 mb-5">
    <h2>
      <v-btn class="mr-2"
             depressed
             fab
             icon
             small
             @click="closeDialog">
        <v-icon color="primary">
          mdi-arrow-left
        </v-icon>
      </v-btn>
      Crear categoría
    </h2>
    <p class="text-subtitle-1 pt-3">Aquí puedes crear todas las categorías que necesites para organizar tus productos o
      servicios.</p>
    <validation-observer ref="observer"
                         v-slot="{invalid}">
      <v-form class="" @submit.prevent="submitCategory">
        <v-row class="mt-8">
          <v-col class="py-0" cols="12" sm="8">
            <validation-provider v-slot="{errors}"
                                 :rules="{required: true}">
              <v-text-field v-model="newCategory.name"
                            :error-messages="errors"
                            clearable
                            color="#00999a"
                            label="Escribe el nombre de la categoría"
                            outlined>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col class="py-0" cols="12" sm="4">
            <v-btn :loading="loading"
                   block
                   class="text-none"
                   color="#bdbdbd"
                   depressed
                   outlined
                   style="background-color: #f5f5f7"
                   type="submit"
                   x-large>
              <span style="color: #008081; font-size: 15px">Crear categoría</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <v-list class="mt-5 mt-sm-0" flat>
      <v-subheader class="px-0">Selecciona y arrasta una categoría para organizarla.</v-subheader>
      <v-list-item-group>
        <draggable :list="categories" class="list-group" v-bind="dragOptions" @end="endSortingCat"
                   @start="startSortingCat">
          <transition-group :name="!drag ? 'flip-list' : null" type="transition">
            <v-list-item v-for="(category, index) in categories"
                         :key="category.id"
                         :class="category.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
                         aria-hidden="true"
                         class="mb-2 list-group-item"
                         color="primary"
                         style="background-color: #ebf5f5"
                         @click="category.fixed = !category.fixed">
              <v-list-item-icon>
                <v-icon>
                  mdi-arrow-all
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">{{ category.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="error"
                       depressed
                       icon
                       rounded
                       @click="deleteCategory(category, $event)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </transition-group>
        </draggable>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import services from "@/services";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})

export default {
  name: "CategoryEditor",
  components: {
    draggable,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: false,
    drag: false,
    loadingDelete: false,
    newCategory: {
      name: ''
    }
  }),
  computed: {
    dragOptions: function () {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    submitCategory: async function () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid)
        return;

      try {
        this.loading = true;
        const addResult = await services.categories.createBusinessCategory(this.$route.params.slug, this.newCategory);
        if (addResult && addResult.data && addResult.data.id) {
          this.categories.push(addResult.data);
          this.newCategory.name = '';
          this.$refs.observer.reset();
          this.$dialog.notify.success('Se ha creado la categoría correctamente.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        } else {
          this.$dialog.notify.error('No se ha podido crear la categoría.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e);
        this.$dialog.notify.error('No se ha podido crear la categoría.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.loading = false;
      }
    },
    deleteCategory: async function (category, event) {
      const res = await this.$dialog.warning({
        text: '¿ Realmente desea eliminar la categoría ?',
        title: 'SeOferta',
        actions: {
          false: 'Cancelar',
          true: {
            text: 'Eliminar',
            color: 'primary'
          }
        },
        persistent: true,
        color: '#d3691f',
        icon: false
      });
      if (res !== true)
        return;

      try {
        const delResult = await services.categories.deleteBusinessCategory(this.$route.params.slug, category);
        if (delResult) {
          let index = -1;
          for (let i = 0; i < this.categories.length; i++) {
            if (this.categories[i].id === category.id) {
              index = i;
            }
          }
          if (index >= 0) {
            this.categories.splice(index, 1);
          }
          this.$dialog.notify.success('La categoría fue eliminada satisfactoriamente.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        } else {
          this.$dialog.notify.error('No se pudo eliminar la categoría.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e);
        this.$dialog.notify.error('No se pudo eliminar la categoría.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      }
      event.stopPropagation();
    },
    closeDialog: function () {
      this.$emit('onBackButton')
    },
    endSortingCat: async function () {
      this.drag = false;
      try {
        await services.categories.orderCategories(this.$route.params.slug, this.categories);
      } catch (error) {
        console.log(error);
      }
    },
    startSortingCat: async function () {
      this.drag = true;
    }
  },
  props: ['categories']
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 56px;
}
</style>
